import { render, staticRenderFns } from "./recommendedInfo.vue?vue&type=template&id=29439e2f&scoped=true"
import script from "./recommendedInfo.vue?vue&type=script&lang=js"
export * from "./recommendedInfo.vue?vue&type=script&lang=js"
import style0 from "./recommendedInfo.vue?vue&type=style&index=0&id=29439e2f&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29439e2f",
  null
  
)

export default component.exports