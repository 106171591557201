<!--
 * @Description: 合作模块
 * @Author: zhang zhen
 * @Date: 2023-02-15 12:06:28
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-07-09 20:06:45
 * @FilePath: /page-sass/src/views/requirement/requirementList/modules/cooperation.vue
-->
<template>
  <div class="recommendedInfo">
    <template v-if="dataList.length">
      <div class="status-bar">
        <img src="~@/assets/warning-bg.png" alt="" class="info-icon">
        <span>采购合作已建立，合作流程请在订单管理中操作</span>
      </div>
      <!-- 上传区域 -->
      <input ref="fileUploaderRef" type="file" class="fileUploader" @change="handleFileUpload">
      <div class="recommendedInfo-card" v-for="(item, index) in dataList" :key="index">
        <div class="top-box">
          <!-- <span>订单编号：{{ item.quoteVo && item.quoteVo.orderNo || '暂无' }}</span> -->
          <span>创建时间：{{ item.quoteVo && item.quoteVo.lastModified || '-' }}</span>
          <template v-if="infoStatus != 2">
            <template v-if="setUserInfo().tradeIdentity != '0'">
            <!-- 框架合同的发起只有 采购商能发起 -->
            <a class="action-btn" @click="handleAddSign(item)" v-if="setUserInfo().tradeIdentity != '0' && !item.contract">签署框架合同</a>
            <!-- 采购商  purchaseSignTime 是空 则  采购商显示签署 ， -->
            <a class="action-btn" @click="handleUploadContract(item)" v-else-if="item.contract && !item.contract.purchaseSignTime">上传合同去签署</a>
            <a class="action-btn" v-else-if="item.contract && item.contract.purchaseSignTime && item.contract.status != 2"">采购商已签署</a>
            <!-- contract.status = 2 说明已签署完成 ，采购商显示拆单 -->
            <a class="action-btn" @click="handleSplitOrder(item)" v-else-if="item.contract && item.contract.status == 2">拆单</a>
          </template>
          <template v-else>
            <!-- supplierSignTime  是空 则 供应商显示签署 ，如果合同已签署   -->
            <a class="action-btn" @click="handleUploadContract(item)" v-if="item.contract && !item.contract.supplierSignTime">上传合同去签署</a>
            <a class="action-btn" v-else-if="item.contract && item.contract.purchaseSignTime">供应商已签署</a>
            <a class="action-btn" v-else-if="item.contract && item.contract.status == 2">签署已完成</a>
          </template>
          </template>
        </div>
        <a-row :gutter="10" type="flex" justify="space-between">
          <a-col :span="20" class="info-left">
            <div class="top-info">
              <!-- <img src="/cover_base.png" alt="" class="cover"> -->
              <img :src="item.companyInfo.picture || '/cover_base.png'" alt="" class="cover" />
              <div class="info-view">
                <div class="main-title">
                  <span class="label">{{ item.companyInfo.businessName || '-' }}</span>
                  <img src="/passTag.png" alt="" class="tag">
                </div>
                <div class="location-info">
                  <span style="margin-right: 13px;">{{ item.companyInfo.companyTypeDictName || '-' }} | {{
      item.companyInfo.industryDictName || '-'
    }}</span>
                  <img src="/location.png" alt="" class="location">
                  <span class="area-name">{{ item.companyInfo.cityName || '' }} {{ item.companyInfo.areaName || '' }}</span>
                </div>
                <div class="tags-list">
                  <div class="tags-list-item" v-for="row in item.companyInfo.leftTagList" :key="row">
                    <span>
                      <a-tooltip slot="suffix" :title="row">
                        {{ row }}
                      </a-tooltip>
                    </span>
                  </div>
                </div>
                <div class="info-box-area">
                  <div class="counter">
                    <span class="label">主营包装:</span>
                    <span class="category" v-if="!item.companyInfo.mainPackagingDictNameList || !item.companyInfo.mainPackagingDictNameList.length">暂无</span>
                    <span class="category" v-for="i in item.companyInfo.mainPackagingDictNameList" :key="i">{{ i
                      }}</span>
                  </div>
                  <a class="view-info" :href="`/companyInfoPage?id=${item.companyInfo.businessId}`">查看企业 ></a>
                </div>

              </div>
            </div>
          </a-col>
          <a-col :span="4" class="rightCard">
            <a-button type="primary" @click="handleJump(item)">
              <img src="/orderIcon.png" alt="" class="invited-icon">
              <span>查看订单</span>
            </a-button>
            <a-button type="primary" ghost @click="handleChat(item.companyInfo)">
              <img src="~@/assets/message-3-line.png" alt="" class="invited-icon">
              <span>在线咨询</span>
            </a-button>
          </a-col>
        </a-row>
      </div>
    </template>
    <EmptyArea v-else description="暂无合作供应商记录" />
    <!-- 签署框架合同 -->
    <SignModal ref="SignModalRef" @ok="handleLoadInfo" />
    <!-- 开票的提示 -->
    <a-modal title="温馨提示" :visible="showNotice" okText="是" cancelText="否" centered @ok="handleNextStep"
      @cancel="showNotice = false">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>您当前的角色是采购个人,请问是否需要开票？</span>
      </div>
      <div class="err-desc">(* 个人名义不能开具增值税电子发票)</div>
    </a-modal>
    <!-- 个人开票 -->
    <InvoicePerson ref="InvoicePersonRef" @nextStep="handleNextSign" />
    <!-- 商业开票 -->
    <InvoiceBusiness ref="InvoiceBusinessRef" @nextStep="handleNextSign" />
    <!-- 拆单子 -->
    <splitOrder ref="splitOrderRef" @ok="handleLoadInfo" />
  </div>
</template>

<script>
import { getAction, uploadAction, postAction } from '@/api/manage'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import ChatMixin from '@/mixins/chatMixins'
import SignModal from './signModal.vue'
import InvoicePerson from './InvoicePerson.vue'
import InvoiceBusiness from './InvoiceBusiness.vue'
import { mapGetters } from 'vuex'
import splitOrder from './splitOrder.vue'

export default {
  name: 'cooperation',
  components: {
    EmptyArea,
    SignModal,
    InvoicePerson,
    InvoiceBusiness,
    splitOrder
  },
  mixins: [ChatMixin],
  props: {
    purchaseId: {
      type: String,
      default: ''
    },
    infoStatus: {
      type: [Number, String],
      default: 1,
    },
  },
  data() {
    return {
      info: null,
      dataList: [],
      businessDTO: {},
      companyInfo: {
        leftTagList: [],
        mainPackagingDictNameList: []
      },
      activeInfo: {},
      showNotice: false
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleReloadData() {

    },
    handleUploadContract(item) {
      this.activeInfo = {
        contractNo: item.contract.contractNo || ''
      };
      const uploader = this.$refs.fileUploaderRef;
      uploader.value = '';
      uploader.click();
    },
    handleFileUpload(e) {
      let selectedFile = event.target.files[0];
      let formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('dir', 'images');
      uploadAction('/file/images/upload', formData).then(res => {
        const { success, message, data } = res;
        if (success) {
          // 触发签单接口
          postAction('/v1/contract/framework/offline/sign', {
            contractFile: data.url,
            contractNo: this.activeInfo.contractNo || ''
          }).then(res => {
            const { success, message } = res
            if (success) {
              this.handleLoadInfo();
            } else {
              this.$message.error(message)
            }
          })
        } else {
          this.$message.error(message)
        }
      })
    },
    // 开票
    handleAddTicket() {
      // 检测下当前用户是采购商个人弹出提示
      const { tradeIdentity, roleType, businessId } = this.setUserInfo();
      if (tradeIdentity == '1' && roleType != 'BUSINESS') {
        this.showNotice = true;
      } else {
        this.$refs.InvoiceBusinessRef.handleOpen({ businessId })
      }
    },
    // 个人开票签单确认
    handleNextStep() {
      this.showNotice = false;
      this.$refs.InvoicePersonRef.handleOpen({ businessId: this.setUserInfo().businessId })
    },
    handleNextSign() {
      this.$refs.signModalRef.handleOpen(this.activeInfo)
    },
    //发起签单
    handleAddSign(item) {
      // 签单之前先检查这个人有没有开票信息
      this.activeInfo = {
        purchaseId: this.purchaseId,
        quoteId: item.quoteVo.quoteId
      }
      getAction('/business/invoice/queryByBusinessId').then(res => {
        const { data } = res;
        if (!data) {
          this.handleAddTicket();
        } else {
          this.$refs.SignModalRef.handleOpen({
            purchaseId: this.purchaseId,
            quoteId: item.quoteVo.quoteId
          })
        }
      })
    },
    // businessId
    handleLoadInfo() {
      this.dataList = [];
      getAction('/quote/confirm/get', {
        purchaseId: this.purchaseId
      }).then(res => {
        const { success, data } = res
        if (success && data && data.length) {
          // this.dataList = 
          data.map(i => {
            const { businessDTO } = i;
            if (businessDTO) {
              let params = { ...i };
              // const { capacity, supplierPurchasePlan } = businessDTO
              let capacity = businessDTO.supplierPurchasePlan || businessDTO.capacity || null
              params.companyInfo = businessDTO;
              params.companyInfo.mainPackagingDictNameList = []
              if (capacity && capacity.fromingDictName) {
                params.companyInfo.mainPackagingDictNameList = capacity.fromingDictName.split(',');
              }
              if (capacity) {
                const { yearTurnoverDictName, minOrderCountDictName, sampleTestDictName, authMethodDictName, printingMethodDictName, productPackageDictName, purchasingMethodDictName } = capacity;
                let sampleTestDictNameList = [], authMethodDictNameList = [], printingMethodDictNameList = [];
                if (sampleTestDictName) {
                  sampleTestDictNameList = sampleTestDictName.split(',').map(item => `支持${item}`);
                }
                if (authMethodDictName) {
                  authMethodDictNameList = authMethodDictName.split(',').map(item => `支持${item}`);
                }
                if (printingMethodDictName) {
                  printingMethodDictNameList = printingMethodDictName.split(',').map(item => `支持${item}`);
                }
                let tagList = ['年营业额：' + (yearTurnoverDictName || '暂无'), '最小下单量：' + (minOrderCountDictName || '暂无'), ...sampleTestDictNameList, ...authMethodDictNameList, ...printingMethodDictNameList, productPackageDictName, purchasingMethodDictName].filter(item => !!item);
                params.companyInfo.leftTagList = tagList.slice(0, 5);
              }
              this.dataList.push(params)
            }
          })
        }
      })
    },
    handleSplitOrder({ quoteVo, contract, businessDTO }) {
      this.$refs.splitOrderRef.handleSplitOrder({
        quoteId: quoteVo.quoteId,
        purchaseId: this.purchaseId,
        supplierBusinessId: businessDTO.businessId,
        supplierBusinessName: businessDTO.businessName,
        refContractNo: contract.contractNo || ''
      })
    },
    // 查看订单详情
    handleJump(item) {
      if (!item.quoteVo.orderNo) return this.$message.error('当前订单还未生成，请签署完协议再试');
      this.$router.push(
        `/orderManagement/orderDetailsForUser?orderNo=${item.quoteVo.orderNo}&purchaseId=${this.purchaseId}`
      )
    }
  }
}
</script>

<style lang="less" scoped>
.recommendedInfo {
  &-memo {
    color: #333;
    margin-bottom: 8px;
  }

  &-card {
    background: #f7f8fa;
    padding: 25px;
    margin-bottom: 25px;

    // height: 232px;
    .top-box {
      display: flex;
      align-items: center;
      grid-gap: 0 24px;
      padding-bottom: 8px;
      border-bottom: 1px solid #EFEFEF;
      margin-bottom: 15px;

      span {
        line-height: 18px;
        color: #666666;
        font-size: 12px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .top-info {
      width: 100%;
      .flexLayout(@justifyContent: flex-start; @alignItem: flex-start);
      // margin-bottom: 8px;
      padding-right: 26px;

      .cover {
        width: 126px;
        height: 126px;
        border-radius: 4px;
        border: 1px solid #EFEFEF;
      }

      .info-box {
        margin-left: 15px;
        flex: 1 0 0;
        margin-right: 40px;

        .title {
          color: #000;
          font-size: 16px;
          margin-bottom: 20px;
          font-weight: 500;
        }
      }
    }

    .labelBox {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;

      .label {
        display: inline-block;
        // width: 36px;
        line-height: 19px;
        color: #000000A6;
        // margin-right: 8px;
        font-size: 12px;
      }

      .label-value {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;
        color: #000000A6;
      }

      .label-tag {
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
        line-height: 19px;
        // padding: 4px 0;
        // width: 119px;
        .text-emphasis();

      }
    }
  }

  .info-left {
    width: 84.533334%;
  }

  .rightCard {
    border-left: 1px solid #EFEFEF;
    padding-left: 33px !important;
    height: 88px;
    .flexLayout(@direction: column; @justifyContent: space-between; @alignItem: flex-end);
    width: 15.466666%;

    .ant-btn {
      width: 110px;
      height: 36px;
      line-height: 36px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;

      .invited-icon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }

      &.last-btn {
        color: #595959;

        img.icon {
          margin-right: 6px;
        }
      }

      &.invited-btn {
        color: #8C8C8C;
        font-size: 14px;
        background-color: #E8E9EB;
        border-color: #E8E9EB;
        cursor: not-allowed;

      }
    }
  }
}

.info-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card-info-box {
  width: 160px;

  &:not(:last-of-type) {
    margin-right: 25px;
  }

  .cover-img {
    width: 160px;
    height: 120px;
    border-radius: 8px;
  }

  .title {
    margin-top: 10px;
    color: #222222;
    .text-emphasis();

  }
}

::v-deep .ant-btn {
  font-size: 14px;
}

.status-bar {
  display: flex;
  align-items: center;
  background-color: #FF7D2F1A;
  height: 40px;
  border-radius: 4px;
  padding: 10px 16px 10px 16px;
  margin-bottom: 20px;

  .info-icon {
    width: 20px;
    margin-right: 8px;
  }

  span {
    color: #000000D9;
  }
}

.top-box {
  position: relative
}

.action-btn {
  position: absolute;
  right: 0;
  top: 0;
}

@import '~@/styles/companyCard.less';

.info-card {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

.err-desc {
  color: #EE4261;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  padding-left: 31px;
  margin-top: 6px;
}

.fileUploader {
  display: none;
}
</style>
